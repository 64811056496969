/**
 * Component for Hero
 *
 * @module ui/modules/Hero
 * @memberof -Common
 */
import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import PaletteHeroFullBleed from 'web-palette/dist/components/HeroFullBleed/HeroFullBleed';
import PaletteHeroFullWidthCompact from 'web-palette/dist/components/HeroFullWidthCompact/HeroFullWidthCompact';
import PaletteHeroHalfImage from 'web-palette/dist/components/HeroHalfImage/HeroHalfImage';
import PaletteHeroShadowBox from 'web-palette/dist/components/HeroShadowbox/HeroShadowbox';
import PaletteHeroTextOnly from 'web-palette/dist/components/HeroTextOnly/HeroTextOnly';
import { actionFormatter } from 'web-palette/dist/util/actionFormatter/actionFormatter';

import { useIntersectionProcessor } from '@ulta/core/hooks/useIntersectionProcessor/useIntersectionProcessor';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { getButtonHtml } from '@ulta/core/utils/getButtonHtml/getButtonHtml';
import { getExternalAction } from '@ulta/core/utils/getExternalAction/getExternalAction';
import { getLinkHtml } from '@ulta/core/utils/getLinkHtml/getLinkHtml';
import { getUltaAssetHtml } from '@ulta/core/utils/getUltaAssetHtml/getUltaAssetHtml';

/**
 * Represents a Hero component
 *
 * @method
 * @param { HeroProps} props - React properties passed from composition
 * @returns Hero
 */
export const Hero = function( props ){
  const { breakpoint } = useDeviceInflection();
  const heroRef = useRef( null );
  const {
    // Hero variant related props
    variant,
    // Image related props
    image,
    mobileImage,
    tabletImage,
    thumbnailImage,
    // Text related props
    actionGroup,
    actionGroups,
    body,
    bodySecondLine,
    eyebrow,
    headline,
    headlineTag,
    tag,
    textColor,
    textPosition,
    // Hero styling props
    backgroundColor,
    shadowColor,
    style
  } = props;

  /** Setting each Hero imported in from Palette */
  const PalHeroTextOnly = PaletteHeroTextOnly.default || PaletteHeroTextOnly;
  const PalHeroFullWidthCompact = PaletteHeroFullWidthCompact.default || PaletteHeroFullWidthCompact;
  const PalHeroFullBleed = PaletteHeroFullBleed.default || PaletteHeroFullBleed;
  const PalHeroHalfImage = PaletteHeroHalfImage.default || PaletteHeroHalfImage;
  const PalHeroShadowBox = PaletteHeroShadowBox.default || PaletteHeroShadowBox;

  /** Getting each Hero variant to set which one to display in the return */
  const isTextOnly = variant === 'textOnly';
  const isFullWidthCompact = variant === 'fullWidthCompact';
  const isFullBleed = variant === 'fullBleedHero';
  const isHalfImage = variant === 'halfImage';
  const isShadowBox = variant === 'shadowBox';

  /** Objects for external media and actions coming from Amplience */
  let formattedMedia;
  let formattedThumbnail;
  let formattedAction;
  let formattedActionFullyClick;

  /** Breakpoint settings for incoming media */
  const isMobile = breakpoint?.isSmallDevice();
  const isDesktop = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'LG' || breakpoint.CURRENT_BREAKPOINT === 'XL' );
  const isTablet = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'MD' );

  /** Action check for formatters */
  const showActionGroupDefault = actionGroup && ( actionGroup.action1?.label || actionGroup.action2?.label );
  const showActionGroups = actionGroups && actionGroups.actions[0]?.label;
  const hasAction = ( showActionGroupDefault || showActionGroups );

  /** This checks if there is 1 action and sets the fullyClickableParent to true if so */
  const fullyClickableParent = showActionGroups && actionGroups.actions?.length === 1;
  const isKnockoutLink = ( textColor === 'content-knockout' );
  const alignment = ( textPosition === 'center-center' || textPosition === 'bottom-center' ) ? 'center' : 'left';

  /** Image check for formatters */
  const hasImage = ( image || mobileImage || tabletImage );

  /** Formatters for incoming media and actions */
  if( hasImage ){
    formattedMedia = getUltaAssetHtml( { image: image, mobileImage: mobileImage, tabletImage: tabletImage, isMobile: isMobile, isDesktop: isDesktop, isTablet: isTablet } );
  }

  if( hasAction ){
    formattedAction = getExternalAction( { actionGroup: actionGroup, actionGroups: actionGroups, isKnockoutLink: isKnockoutLink, alignment: alignment } );
  }

  if( thumbnailImage ){
    formattedThumbnail = getUltaAssetHtml( { thumbnailImage: thumbnailImage } );
  }

  if( thumbnailImage ){
    formattedThumbnail = getUltaAssetHtml( { thumbnailImage: thumbnailImage } );
  }

  if( fullyClickableParent ){
    formattedActionFullyClick = actionFormatter(
      { action: actionGroups?.actions[0], style: actionGroups?.style, fullyClickableParent: fullyClickableParent },
      { getButtonMarkup: getButtonHtml, getLinkMarkup: getLinkHtml } );
  }

  useIntersectionProcessor( {
    ...props,
    componentRef: heroRef
  } );

  return (
    <div
      className='Hero'
      ref={ heroRef }
    >
      { isTextOnly &&
        <PalHeroTextOnly
          externalAction={ formattedAction }
          externalActionFullyClick={ formattedActionFullyClick }
          externalThumbnailImage={ formattedThumbnail }
          backgroundColor={ backgroundColor }
          body={ body }
          eyebrow={ eyebrow }
          fullyClickableParent={ fullyClickableParent }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isFullWidthCompact &&
        <PalHeroFullWidthCompact
          externalAction={ formattedAction }
          externalActionFullyClick={ formattedActionFullyClick }
          externalMedia={ formattedMedia }
          body={ body }
          bodySecondLine={ bodySecondLine }
          eyebrow={ eyebrow }
          fullyClickableParent={ fullyClickableParent }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
          textPosition={ textPosition }
        />
      }
      { isFullBleed &&
        <PalHeroFullBleed
          externalAction={ formattedAction }
          externalActionFullyClick={ formattedActionFullyClick }
          externalMedia={ formattedMedia }
          backgroundColor={ backgroundColor }
          body={ body }
          eyebrow={ eyebrow }
          fullyClickableParent={ fullyClickableParent }
          headline={ headline }
          headlineTag={ headlineTag }
          style={ style }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isHalfImage &&
        <PalHeroHalfImage
          externalAction={ formattedAction }
          externalActionFullyClick={ formattedActionFullyClick }
          externalMedia={ formattedMedia }
          backgroundColor={ backgroundColor }
          body={ body }
          eyebrow={ eyebrow }
          fullyClickableParent={ fullyClickableParent }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isShadowBox &&
        <PalHeroShadowBox
          externalActionFullyClick={ formattedActionFullyClick }
          externalAction={ formattedAction }
          externalMedia={ formattedMedia }
          externalThumbnailImage={ formattedThumbnail }
          backgroundColor={ backgroundColor }
          body={ body }
          eyebrow={ eyebrow }
          fullyClickableParent={ fullyClickableParent }
          headline={ headline }
          headlineTag={ headlineTag }
          shadowColor={ shadowColor }
          style={ style }
          tag={ tag }
        />
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef Hero
 * @type {object}
 * @property {object} actionGroup - Allows passing an object to the actionGroup object
 * @property {object} actionGroups - Allows passing an object to the actionGroups object
 * @property {string} backgroundColor - background color of the hero body content
 * @property {string} body - text/description of the hero body
 * @property {string} bodySecondLine - text/description of the hero bodySecondLine
 * @property {string} eyebrow - smaller text above the title
 * @property {string} headline - title text of the hero body
 * @property {string} headlineTag - headline tag of the hero body
 * @property {object} image - Image component for the desktop view
 * @property {object} mobileImage - Image component for the mobile view
 * @property {object} tabletImage - Image component for the tablet view
 * @property {string} shadowColor - sets the color of the shadow for the ShadowboxHero
 * @property {string} style - sets the style of the Hero
 * @property {object} tag - tag component above the eyebrow
 * @property {string} textColor - sets the color of the text
 * @property {string} textPosition - sets the position of the text
 * @property {object} thumbnailImage - thumbnail image for the textOnlyHero and ShadowboxHero
 * @property {string} variant - sets the variant of the Hero
 */

const propTypes = {
  /**
   * Allows passing an object to the actionGroup object
   */
  actionGroup: PropTypes.object,
  /**
   * Allows passing an object to the actionGroups object
   */
  actionGroups: PropTypes.object,
  /**
   * background color of the hero body content
   */
  backgroundColor: PropTypes.string,
  /**
   * text/description of the hero body
   */
  body: PropTypes.string,
  /**
   * text/description of the hero bodySecondLine
   */
  bodySecondLine: PropTypes.string,
  /**
   * smaller text above the title
   */
  eyebrow: PropTypes.string,
  /**
   * title text of the hero body
   */
  headline: PropTypes.string,
  /**
   * headline tag of the hero body
   */
  headlineTag: PropTypes.string,
  /**
   * Image component for the desktop view
   */
  image: PropTypes.object,
  /**
   * Image component for the mobile view
   */
  mobileImage: PropTypes.object,
  /**
   * Image component for the tablet view
   */
  tabletImage: PropTypes.object,
  /**
   * sets the color of the shadow for the ShadowboxHero
   */
  shadowColor: PropTypes.string,
  /**
   * sets the style of the Hero
   */
  style: PropTypes.string,
  /**
   * tag component above the eyebrow
   */
  tag: PropTypes.object,
  /**
   * sets the color of the text
   */
  textColor: PropTypes.string,
  /**
   * sets the position of the text
   */
  textPosition: PropTypes.string,
  /**
   * thumbnail image for the textOnlyHero and ShadowboxHero
   */
  thumbnailImage: PropTypes.object,
  /**
   * sets the variant of the Hero
   */
  variant: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} headlineTag - headlineTag: 'h2' - sets the default for the headline tag
 */
export const defaultProps =  {
  headlineTag: 'h2'
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;